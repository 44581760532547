import { Link } from 'gatsby'
import React from 'react'

const CareerPositionCard = ({ position }) => {
  const { role, location, slug } = position
  const link = `/careers/positions/${slug?.current}`

  return (
    <div className="border-b-[2px] my-[3%] pb-[3%] flex flex-col md:flex-row w-full justify-between md:items-center">
      <div className='md:max-w-[80%]'>
        <p className="h4 pb-[1%] md:pb-[2%]">{role}</p>
        <p className="body mb-[3%] md:mb-0">{location}</p>
      </div>
      <Link to={link} className='text-nowrap'>
        <span className="h6 text-right mb-[1%]">View Position</span>
      </Link>
    </div>
  )
}

export default CareerPositionCard

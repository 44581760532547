import React from 'react'
import { Link } from 'gatsby'
import Meta from '../../../components/Meta'
import { useCareerPositions } from '../../../hooks/useCareerPositions'
import CareerPositionCard from '../../../components/CareerPositionCard'

const CareerPositions = () => {
  const { careerPositions, allCareerPositions } = useCareerPositions()
  const { title, copy, seo } = careerPositions

  return (
    <>
      <Meta pageTitle={seo?.title || 'Career Positions'} />
      <section className="w-full pt-24 pb-12 md:header-padding relative md:pb-[5%]">
        <div className="px-4 pt-9 md:flex flex-col md:px-[5%] md:pt-[2%]">
          <h1 className="h3 pb-4">{title}</h1>
          <p className="body-sm md:max-w-3xl">{copy}</p>
        </div>
      </section>
      <section className="min-h-[50vh] px-4 pb-24 md:px-[5%] h-full">
        {allCareerPositions?.length > 0 ? (
          allCareerPositions.map((position, index) => {
            return <CareerPositionCard key={index} position={position} />
          })
        ) : (
          <p className="body-sm">
            There are currently no open positions available. However, if you'd
            like to be considered for future opportunities, please fill out the
            general application below.
          </p>
        )}
        <div className="border-b-[2px] my-[3%] pb-[3%] flex flex-col md:flex-row w-full justify-between md:items-center">
          <div>
            <p className="h4 pb-[1%] md:pb-0">General Application</p>
          </div>
          <Link to="/careers/positions/general-application">
            <span className="h6 text-right mb-[1%]">View Position</span>
          </Link>
        </div>
      </section>
    </>
  )
}

export default CareerPositions

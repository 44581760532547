import { graphql, useStaticQuery } from 'gatsby'

export const useCareerPositions = () => {
  const data = useStaticQuery(graphql`
    query {
      careerPositions: sanityCareerPositions {
        seo {
          title
        }
        title
        copy
      }
      allSanityCareerPosition {
        nodes {
          role
          location
          slug {
            current
          }
        }
      }
    }
  `)

  const { careerPositions } = data || {}
  const allCareerPositions = data?.allSanityCareerPosition?.nodes || []

  return { careerPositions, allCareerPositions }
}
